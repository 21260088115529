<template>
         <section class="page_title  pdt_80  pdb_40">
               <div class="container">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-12 col-md-12 text-center">
                        <!---------col----------->
                        <div class="content_box">
                           <h6>Request or Submit</h6>
                           <h1> Information Tool</h1>
                           <ul class="bread_crumb text-center">
                              <li class="bread_crumb-item"><a href="#">Home</a></li>
                              <li class="bread_crumb-item active">Information Tool</li>
                           </ul>
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
               </div>
            </section>




                      <!-----------blog_v1--------------->
            <section class="blog_v1 pdt_80  pdb_100">
               <div class="container">

                  <div class="row">
                  <div class="col-lg-4">
                        <!---------blog_box----------->
                        <div class="blog_box type_one">
                           <div class="image_box">
                              <img src="@/assets/image/resources/gavi.png" class="img-fluid" alt="img" />
                              <div class="overlay">
                                 <a href="https://www.gavi.org/covid19" target="_blank">
                                    <span style="text-alignment:center!important; margin-left:10px !important">Gavi.org</span>
                                
                                 </a>
                              </div>
                           </div>
                            <p>GAVI, officially Gavi, the Vaccine Alliance is a public–private global health partnership with the goal of increasing access to immunization in poor countries.</p>
                            <a href="https://www.gavi.org/covid19" target="_blank" class="read_more tp_one">Go to Site<span class="linearicons-arrow-right"></span></a>
                        </div>
                        <!---------blog_box end----------->
                     </div>
                 
                     <!----------row-end---------->
                  </div>
                  
            
               </div>
            </section>
            <!-----------blog_v1-end-------------->
</template>